import QuarterlyReportList from './list';
import QuarterlyReportShow from './show';
import AssessmentIcon from '@mui/icons-material/Assessment';

const QuarterlyReports = {
    list: QuarterlyReportList,
    show: QuarterlyReportShow,
    icon: AssessmentIcon
}

export default QuarterlyReports;
