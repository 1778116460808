import YearlyReportList from './list';
import YearlyReportShow from './show';
import AssessmentIcon from '@mui/icons-material/Assessment';

const YearlyReports = {
    list: YearlyReportList,
    show: YearlyReportShow,
    icon: AssessmentIcon
}

export default YearlyReports;
