import PackagingCostList from './list';
import PackagingCostCreate from './create';
import PackagingCostEdit from './edit';
import PaymentIcon from '@mui/icons-material/Payment';

const PackagingCosts = {
    list: PackagingCostList,
    create: PackagingCostCreate,
    edit: PackagingCostEdit,
    icon: PaymentIcon
}

export default PackagingCosts;
