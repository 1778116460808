import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, NumberInput,DateInput, DateTimeInput } from 'react-admin';

const PurchasePriceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <NumberInput source="amount" step={1}/>
            <NumberInput source="order_number"/>
            <ReferenceInput label="Product" source="product" reference="products" allowEmpty>
                <AutocompleteInput fullWidth={true} />
            </ReferenceInput>
            <DateInput source="order_start_date" />
            <DateInput source="order_end_date" />
        </SimpleForm>
    </Edit>
);

export default PurchasePriceEdit;