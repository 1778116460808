import AccountList from './list';
import AccountCreate from './create';
import AccountEdit from './edit';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const Accounts = {
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    icon: ManageAccountsIcon
}

export default Accounts;
