import { Show, TextField, DateField, useRecordContext, TabbedShowLayout, Tab, SimpleShowLayout, ReferenceField, NumberField } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Box, FormControlLabel, Grid, InputLabel } from '@mui/material';
import { PieChart, BarChart, BarSeries, Tooltip } from '@mui/x-charts';
import { useState } from 'react';

const AccountName = () =>{
    const record = useRecordContext();

    if (!record) return null;
    return record.account_id ?
    <ReferenceField label="Account" source="account_id" reference="accounts">
        <TextField source="name" />
    </ReferenceField> : 'All';
}

const Percent = ({type}) => {
    const record = useRecordContext();
    let obtained = record.number_of_orders;
    let bg = 'white';
    if (!record) return null;
    switch(type){
        case 'delivered':
            obtained = record.number_of_delivered_orders;
            bg = 'darkgreen';
            break;
        case 'shipped':
            obtained = record.number_of_shipped_orders;
            bg = 'orange';
            break;
        case 'pending':
            obtained = record.number_of_pending_orders;
            bg = 'lightgreen';
            break;
        case 'failed':
            obtained = record.number_of_failed_orders;
            bg = 'red';
            break;
        case 'returned':
            obtained = record.number_of_returned_orders;
            bg = 'red';
            break;
        case 'cancelled':
            obtained = record.number_of_cancelled_orders;
            bg = 'orange';
            break;
    }

    const percent = (obtained/(record.number_of_orders-record.number_of_cancelled_orders)*100).toFixed(2);

    return <Box sx={{ display: 'flex', margin: '10px', position: 'relative', height: '30px' }}><span style={{display: 'flex', justifyContent: 'space-between',alignItems: 'center', position: 'absolute', color: 'white', width: 'calc(100% - 20px)', height: '100%', left: 0,top: 0, padding: '0 10px', zIndex: 2 }}>Number of {type} orders<strong>{percent+'%'}</strong><span>{obtained}</span></span><span style={{position: 'absolute', width: '100%', background: '#7e7e7e', height: '100%', left: 0,top: 0,zIndex: 0}}></span><span style={{position: 'absolute', width: percent+'%', background: bg, height: '100%', left: 0,top: 0,zIndex: 1}}></span></Box>;
}

const TotalNumberOfOrdersExceptCancelled = () => {
    const record = useRecordContext();
    let total = record.number_of_orders;
    let cancelled = record.number_of_cancelled_orders;
    if (!record) return null;

    return <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:0, fontWeight:500, justifyContent: 'space-between' }}><label>Total Number of Orders</label>{total-cancelled}</Box>
}

const PercentOfRevenue = () => {
    
    const record = useRecordContext();
    if (!record) return null;

    let data = record.revenuePercentages
    if (!data) return null;
      
    const total = data.find((item)=>item.label == 'Revenues')['value'];
    data = data.filter(function( item ) {
        return item.label !== 'Revenues';
    });

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <PieChart
                innerRadius={30}
                outerRadius={100}
                paddingAngle={5}
                cornerRadius={5}
                startAngle={-90}
                endAngle={180}
                cx={150}
                cy={150}
                width={600}
                height={600}
                series={
                    [
                        {
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            data: data,
                            valueFormatter: (v) => {
                                const percent = (v.value / total)*100;
                                return `${(percent).toFixed(2)}% of revenue`;
                            },
                        }
                    ]
                }
                slotProps={{
                    legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'middle' },
                    padding: 0
                    }
                }}
                >
            </PieChart>
        </div>
    );
}

const OrdersGrowthFromLastPeriod = () =>{
    const record = useRecordContext();
    if (!record) return null;

    const data = record.comparedOrders;
    if (!data) return null;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <h2>Orders Growth Compared to Previous Month</h2>
            <BarChart
                width={600}
                height={400}
                dataset={data}
                xAxis={[{ scaleType: 'band', dataKey: 'type' }]}
                series={
                    [
                        {
                            dataKey: 'previous',
                            label: 'Previous',
                            valueFormatter: (v, {dataIndex}) => {
                                return v;
                            },
                        },
                        {
                            dataKey: 'current',
                            label: 'Current',
                            valueFormatter: (v, {dataIndex}) => {
                                const previous = data[dataIndex].previous;
                                const current = data[dataIndex].current;
                                let percent = 0;
                                let result = 'from previous month';

                                if ( current >= previous ){
                                    percent = 1-(previous/current);
                                    result = 'Increase '+result;
                                }else{
                                    percent = 1-(current/previous);
                                    result = 'Decrease '+result;
                                }

                                percent = percent*100;

                                return (<div>
                                    {v} <strong>{(percent).toFixed(2)+'%'} {result}</strong>
                                </div>);
                            },
                        }
                    ]
                }
                >
            </BarChart>
        </div>
    );
}

const IncomeGrowthFromLastPeriod = () =>{
    const record = useRecordContext();
    if (!record) return null;

    const data = record.comparedIncomes;
    if (!data) return null;

    return (
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <h2>Income Growth Compared to Previous Month</h2>
            <BarChart
                width={600}
                height={400}
                dataset={data}
                xAxis={[{ scaleType: 'band', dataKey: 'type' }]}
                series={
                    [
                        {
                            dataKey: 'previous',
                            label: 'Previous',
                            valueFormatter: (v, {dataIndex}) => {
                                return v;
                            },
                        },
                        {
                            dataKey: 'current',
                            label: 'Current',
                            valueFormatter: (v, {dataIndex}) => {
                                const previous = data[dataIndex].previous;
                                const current = data[dataIndex].current;
                                let percent = 0;
                                let result = 'from previous month';

                                if ( current >= previous ){
                                    percent = 1-(previous/current);
                                    result = 'Increase '+result;
                                }else{
                                    percent = 1-(current/previous);
                                    result = 'Decrease '+result;
                                }

                                percent = percent*100;

                                return (<div>
                                    {v} <strong>{(percent).toFixed(2)+'%'} {result}</strong>
                                </div>);
                            },
                        }
                    ]
                }
                >
            </BarChart>
        </div>
    );
}

const DarazExpenses = () => {
    const record = useRecordContext();
    if (!record) return null;

    const daraz_expenses = record.daraz_expenses_details;
    if (!daraz_expenses) return null;

    let _return = [];

    daraz_expenses.forEach(item => {
        _return.push(<div style={{display: 'flex', justifyContent: 'space-between'}}><span>{item.expense_type}:</span> <strong>{item.amount}</strong></div>);
    });

    return (<>{_return}</>)
}

const MonthlyReportShow = (props) => {

    let [expensesToggle,setExpensesToggle] = useState(false);

    return (
        <Show {...props}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'Grid', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500 }}>
                        <label>Account</label>
                        <AccountName />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'Grid', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500 }}>
                        <label>Start Date</label>
                        <DateField source="start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'Grid', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500 }}>
                        <label>End Date</label>
                        <DateField source="end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={{ display: 'Grid', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500 }}>
                        <label>Type</label>
                        <TextField source="type" />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', padding:2, fontWeight:700, justifyContent: 'center' }}>Summary</Box>
                    <hr />
                </Grid>
                <Grid item xs={6} borderLeft={'solid 1px #000'}>
                    <Box sx={{ display: 'flex', padding:2, fontWeight:700, justifyContent: 'center' }}>Income Sheet</Box>
                    <hr />
                </Grid>
            </Grid>
            <Grid container spacing={0} >
                <Grid item xs={6}>
                    <Percent type="delivered"/>
                    <Percent type="shipped"/>
                    <Percent type="pending"/>
                    <Percent type="failed"/>
                    <Percent type="returned"/>
                    <hr />
                    <TotalNumberOfOrdersExceptCancelled/>
                    <hr />
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}><label>Average Order Value: </label><NumberField style={{fontWeight: 'bold'}} source="average_order_value"/></Box>
                    <hr/>
                    <OrdersGrowthFromLastPeriod />
                    <IncomeGrowthFromLastPeriod />
                </Grid>
                <Grid item xs={6} borderLeft={'solid 1px #000'}>
                    <SimpleShowLayout>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Revenues</label>
                        <span><NumberField source="revenues" label={false} style={{fontWeight:700}} /></span>
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between', alignItems: 'center' }}>
                        <label>Purcahses</label>
                        <span>(<NumberField source="purchases" label={false} style={{fontWeight:700}} />)</span>
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Daraz Expenses <button onClick={()=>setExpensesToggle(!expensesToggle)}>Show/Hide Details</button></label>
                        <span>(<NumberField source="daraz_expenses" label={false} style={{fontWeight:700}} />)</span>
                    </Box>
                    <Box sx={{padding: 1,background: '#dadada', maxWidth:'90%', marginLeft:2, marginTop:1, border: '1px solid #aaaaaa', width: '100%', display: expensesToggle?'flex':'none', flexDirection: 'column'}}>
                        <DarazExpenses />
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Costs</label>
                        <span>(<NumberField source="costs" label={false} style={{fontWeight:700}} />)</span>
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Other Expenses</label>
                        <span>(<NumberField source="other_expenses" label={false} style={{fontWeight:700}} />)</span>
                    </Box>
                    <hr />
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Gross Profit/Loss</label>
                        <span><NumberField source="gross_profit" label={false} style={{fontWeight:700}} /></span>
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Taxes</label>
                        <span>(<NumberField source="taxes" label={false} style={{fontWeight:700}} />)</span>
                    </Box>
                    <hr />
                    <Box sx={{ display: 'flex', maxWidth:'90%', paddingLeft:2, paddingTop:1, fontWeight:500, justifyContent: 'space-between' }}>
                        <label>Net Profit/Loss</label>
                        <span><NumberField source="net_profit" label={false} style={{fontWeight:700}} /></span>
                    </Box>
                    <hr />
                    <PercentOfRevenue/>
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    );
}

export default MonthlyReportShow;