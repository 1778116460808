import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, SearchInput, useRecordContext, ReferenceField, NumberField } from 'react-admin';

const filters = [
    <SearchInput source="q" alwaysOn />,
];

const OtherExpenseList = () => (
    <List filters={filters}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField label="Account" source="account_id" reference="accounts">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <NumberField source="amount"/>
            <DateField source="expense_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <DateField source="created_at" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default OtherExpenseList;