import OrderList from './list';
import OrderEdit from './edit';
import OrderShow from './show';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

const Orders = {
    list: OrderList,
    edit: OrderEdit,
    show: OrderShow,
    icon: ShoppingBagIcon
}

export default Orders;
