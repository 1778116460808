import { Create, PasswordInput, SimpleForm, TextInput } from 'react-admin';

const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
            <TextInput source="email" />
            <PasswordInput source="password" />
        </SimpleForm>
    </Create>
);

export default UserCreate;