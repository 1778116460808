import OtherExpenseList from './list';
import OtherExpenseCreate from './create';
import OtherExpenseEdit from './edit';
import PaymentIcon from '@mui/icons-material/Payment';

const OtherExpenses = {
    list: OtherExpenseList,
    create: OtherExpenseCreate,
    edit: OtherExpenseEdit,
    icon: PaymentIcon
}

export default OtherExpenses;
