import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, SearchInput, useRecordContext } from 'react-admin';

const filters = [
    <SearchInput source="q" alwaysOn />,
];

const AuthorizedLink = (props) =>{
    const record = useRecordContext(props);
    return record.access_token ? <span style={{color: 'green'}}>Authorized</span> : <a href={record.authorize_url}>Authorize</a>
}

const AccountList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="created_at" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <AuthorizedLink source="authorize_link" label="Authorize Account" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default AccountList;