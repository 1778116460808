import { Create, SimpleForm, TextInput , ReferenceArrayInput, NumberInput, DateInput, maxValue, AutocompleteArrayInput } from 'react-admin';
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
const date = ("0" + currentDate.getDate()).slice(-2);

const OtherExpenseCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <ReferenceArrayInput label="Account" source="account_ids" reference="accounts">
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <TextInput source="name" />
            <NumberInput source="amount" step={0.01}/>
            <DateInput source="expense_date" validate={maxValue(year+'-'+month+'-'+date)} />
        </SimpleForm>
    </Create>
);

export default OtherExpenseCreate;