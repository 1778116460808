import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, NumberInput,DateInput, DateTimeInput, maxValue } from 'react-admin';
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
const date = ("0" + currentDate.getDate()).slice(-2);

const OtherExpenseEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Account" source="account_id" reference="accounts">
                <AutocompleteInput />
            </ReferenceInput>
            <TextInput source="name" />
            <NumberInput source="amount" step={0.01}/>
            <DateInput source="expense_date" validate={maxValue(year+'-'+month+'-'+date)} />
        </SimpleForm>
    </Edit>
);

export default OtherExpenseEdit;