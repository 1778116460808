import PurchasePriceList from './list';
import PurchasePriceCreate from './create';
import PurchasePriceEdit from './edit';
import PaymentIcon from '@mui/icons-material/Payment';

const PurchasePrices = {
    list: PurchasePriceList,
    create: PurchasePriceCreate,
    edit: PurchasePriceEdit,
    icon: PaymentIcon
}

export default PurchasePrices;
