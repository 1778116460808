import { Show, TextField, DateField, useRecordContext, TabbedShowLayout, Tab, SimpleShowLayout } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Grid, InputLabel } from '@mui/material';

const OrderNumber = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{record.order_number} | {record.tracking_code} (Rs.{record.item_price}/=)</span>;
}

const ProductTabs = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <img src={record.product_main_image} title={record.name} style={{maxHeight:'300px'}} />
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Name</strong>
                        <a href={record.product_detail_url}>{record.name+' | '+record.variation}</a>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Price</strong><span>{record.item_price}</span>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Purchase Price</strong><span>{record.purchase_price}</span>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Packaging Cost</strong><span>{record.status == 'pending' || record.status == 'unpaid' || record.status == 'ready_to_ship' || record.status == 'shipped' ? 0 : parseFloat(record.packaging_cost)}</span>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Status</strong><span>{record.status}</span>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Payout Amount</strong><span>{record.payout_amount}</span>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Gross Profit/loss</strong><span>{record.gross_profit}</span>
                    </SimpleShowLayout>
                </Grid>
                {
                    (record.status == 'returned' || record.status == 'failed') && <Grid item xs={12}>
                        <SimpleShowLayout>
                            <strong>Return Status By Daraz</strong><span>{record.return_status}</span>
                        </SimpleShowLayout>
                    </Grid>
                }
                {
                    (record.status == 'returned' || record.status == 'failed') && <Grid item xs={12}>
                        <SimpleShowLayout>
                            <strong>Return Item Received</strong><span>{record.item_received ? 'Yes' : 'No'}</span>
                        </SimpleShowLayout>
                    </Grid>
                }
                {
                    (record.status == 'returned' || record.status == 'failed') && <Grid item xs={12}>
                        <SimpleShowLayout>
                            <strong>Return Item Payment Received</strong><span>{record.item_payment_recieved}</span>
                        </SimpleShowLayout>
                    </Grid>
                }
                {
                    record.status == 'canceled' && <Grid item xs={12}>
                        <SimpleShowLayout>
                            <strong>Cancel By</strong><span>{record.cancel_return_initiator}</span>
                        </SimpleShowLayout>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <strong>Comments</strong><span>{record.comments}</span>
                    </SimpleShowLayout>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <SimpleShowLayout>
                        <h2>Transactions</h2>
                        {
                            record.transactions && record.transactions.length && record.transactions.map((transaction)=>{
                                return <Grid item xs={12}><SimpleShowLayout>
                                        <h3>{transaction.fee_name}</h3>
                                        <span><strong>Amount: </strong>{transaction.amount}</span>
                                        <span><strong>Type: </strong>{transaction.transaction_type}</span>
                                        <span><strong>Date: </strong>{transaction.transaction_date}</span>
                                        <span><strong>Statement: </strong>{transaction.statement}</span>
                                    </SimpleShowLayout></Grid>
                            })
                        }

                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Grid>
    )
}

const OrderShow = (props) => {
    return (
        <Show title={<OrderNumber/>} {...props}>
            <ProductTabs />
        </Show>
    );
}

export default OrderShow;