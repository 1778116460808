import { Menu } from 'react-admin';

import Users from './users';
import Accounts from './accounts';
import SubMenu from './Submenu';
import MonthlyReports from './monthly_reports';
import QuarterlyReports from './quarterly_reports';
import YearlyReports from './yearly_reports';
import Orders from './orders';

import AssessmentIcon from '@mui/icons-material/Assessment';
import PaymentIcon from '@mui/icons-material/Payment';
import PackagingCosts from './packaging_costs';
import PurchasePrices from './purchase_prices';
import OtherExpenses from './other_expenses';

const CustomMenu = () => (
    <Menu>
        <Menu.Item to="/users" primaryText="Users" leftIcon={<Users.icon/>}/>
        <Menu.Item to="/accounts" primaryText="Accounts" leftIcon={<Accounts.icon/>}/>
        <Menu.Item to="/orders" primaryText="Orders" leftIcon={<Orders.icon/>}/>
        <SubMenu primaryText="Costing" leftIcon={<PaymentIcon/>}>
            <Menu.Item to="/packaging_costs" primaryText="Packaging Costs" leftIcon={<PackagingCosts.icon/>}/>
            <Menu.Item to="/purchase_prices" primaryText="Purchase Price" leftIcon={<PurchasePrices.icon/>}/>
            <Menu.Item to="/other_expenses" primaryText="Other Expenses" leftIcon={<OtherExpenses.icon/>}/>
        </SubMenu>
        <SubMenu primaryText="Reports" leftIcon={<AssessmentIcon/>}>
            <Menu.Item to="/monthly_reports" primaryText="Monthly" leftIcon={<MonthlyReports.icon/>}/>
            <Menu.Item to="/quarterly_reports" primaryText="Qaurterly" leftIcon={<QuarterlyReports.icon/>}/>
            <Menu.Item to="/yearly_reports" primaryText="Yearly" leftIcon={<YearlyReports.icon/>}/>
        </SubMenu>
    </Menu>
);

export default CustomMenu;