import { Edit, useRecordContext, SimpleForm, NumberInput, BooleanInput, TextInput } from 'react-admin';

const ItemTitle = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <span>{record.name}</span>;
}

const OrderEdit = (props) => (
    <Edit {...props} title={<ItemTitle/>} redirect="/orders">
        <SimpleForm>
            <BooleanInput source="item_received" />
            <NumberInput source="item_payment_recieved" />
            <TextInput source="comments" />
        </SimpleForm>
    </Edit>
);

export default OrderEdit;