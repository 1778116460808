import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, SearchInput, useRecordContext } from 'react-admin';

const filters = [
    <SearchInput source="q" alwaysOn />,
];

const UserList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="email" />
            <DateField source="created_at" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default UserList;