import { Edit, PasswordInput, SimpleForm, TextInput } from 'react-admin';

const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextInput source="id" disabled />
            <TextInput source="name" />
            <PasswordInput source="password" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;