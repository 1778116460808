import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, SearchInput, useRecordContext, ReferenceField, NumberField, DateTimeInput, ExportButton } from 'react-admin';

const filters = [
    <SearchInput placeholder="Search Order Number" source="q" alwaysOn />,
    <DateTimeInput label="Start Date" source="order_start_date" />,
    <DateTimeInput label="End Date" source="order_end_date" />,
    <ExportButton />
];

const StartDate = () =>{
    const record = useRecordContext();

    if (!record) return null;
    return record.order_start_date ? <DateField source="order_start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} /> : <></>
}

const EndDate = () =>{
    const record = useRecordContext();

    if (!record) return null;
    return record.order_end_date ? <DateField source="order_end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} /> : <></>
}


const PackagingCostList = () => (
    <List filters={filters}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <NumberField source="amount"/>
            <TextField source="order_number"/>
            <StartDate label="Order Start Date" />
            <EndDate label="Order End Date" />
            <DateField source="created_at" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default PackagingCostList;