import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, NumberInput, DateInput, DateTimeInput } from 'react-admin';

const PackagingCostCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <NumberInput source="amount" step={1}/>
            <NumberInput source="order_number"/>
            <DateInput source="order_start_date" />
            <DateInput source="order_end_date" />
        </SimpleForm>
    </Create>
);

export default PackagingCostCreate;