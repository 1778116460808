import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, SearchInput, useRecordContext, ReferenceField, NumberField, ShowButton, ReferenceInput, DateInput, ExportButton, AutocompleteInput } from 'react-admin';

const filters = [
    <ReferenceInput label="Account" source="account_id" reference="accounts" allowEmpty>
        <AutocompleteInput/>
    </ReferenceInput>,
    <DateInput source="start_date" />,
    <DateInput source="end_date" />,
    <AutocompleteInput label="Report Year" source="report_year" choices={[{id:'2020',name:'2020'},{id:'2021',name:'2021'},{id:'2022',name:'2022'},{id:'2023',name:'2023'},{id:'2024',name:'2024'},{id:'2025',name:'2025'},{id:'2026',name:'2026'},{id:'2027',name:'2027'},{id:'2028',name:'2028'},{id:'2029',name:'2029'},{id:'2030',name:'2030'}]} />,
    <AutocompleteInput label="Report Month" source="report_month" choices={[{id:'1',name:'Jan'},{id:'2',name:'Feb'},{id:'3',name:'Mar'},{id:'4',name:'Apr'},{id:'5',name:'May'},{id:'6',name:'Jun'},{id:'7',name:'Jul'},{id:'8',name:'Aug'},{id:'9',name:'Sep'},{id:'10',name:'Oct'},{id:'11',name:'Nov'},{id:'12',name:'Dec'}]} />,
    <ExportButton />
];

const AccountName = () =>{
    const record = useRecordContext();

    if (!record) return null;
    return record.account_id ?
    <ReferenceField label="Account" source="account_id" reference="accounts">
        <TextField source="name" />
    </ReferenceField> : 'All';
}

const MonthlyReportList = () => (
    <List filters={filters}>
        <Datagrid>
            <TextField source="id" />
            <AccountName label="Account" />
            <DateField source="start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <DateField source="end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <TextField source="type" />
            <ShowButton />
        </Datagrid>
    </List>
);

export default MonthlyReportList;