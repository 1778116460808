import MonthlyReportList from './list';
import MonthlyReportShow from './show';
import AssessmentIcon from '@mui/icons-material/Assessment';

const MonthlyReports = {
    list: MonthlyReportList,
    show: MonthlyReportShow,
    icon: AssessmentIcon
}

export default MonthlyReports;
