import UserList from './list';
import UserCreate from './create';
import UserEdit from './edit';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const Users = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    icon: PersonAddAltIcon
}

export default Users;
