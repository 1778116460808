import { stringify } from 'query-string';
import axios from 'axios';
import { HttpError } from 'react-admin';

const countHeader = 'content-range';

const DataProvider = (
    apiUrl
) => ({
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
                range: `${resource}=${rangeStart}-${rangeEnd}`
            }
        }

        return axios.get(url, options).then(({ headers, data }) => {
            if ( !headers[countHeader] ) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: data.data,
                total: parseInt(headers[countHeader].split('/').pop(),10)
            };
        });
    },

    getOne: async (resource, params) =>{
        const url = `${apiUrl}/${resource}/${params.id}`;
        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token')
            }
        }
        return axios.get(url,options).then(({ data }) => ({
            data: data.data,
        }))
    },

    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
            }
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return axios.get(url,options).then(({ data }) => ({ data: data.data }));
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
                range: `${resource}=${rangeStart}-${rangeEnd}`
            }
        }
        return axios.get(url, options).then(({ headers, data }) => {
            if ( !headers[countHeader] ) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: data.data,
                total: parseInt(headers[countHeader].split('/').pop(),10)
            };
        });
    },

    update: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        let data = params.data;

        if ( data.order_start_date ){
            data.order_start_date = new Date(data.order_start_date+' 00:00:00').toISOString();
        }

        if ( data.order_end_date ){
            console.log(data.order_end_date);
            console.log(new Date(data.order_end_date+' 23:59:59'));
            //data.order_end_date = new Date(data.order_end_date+' 23:59:59').toISOString();
        }

        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
            }
        }
        return await axios.put(url,data,options).then(({data})=>{
            if ( data.data ){
                return Promise.resolve(data);
            }else{
                return Promise.reject(data);
            };
        });
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: async (resource, params) => {
        return Promise.all(
            params.ids.map(id =>{
                const url = `${apiUrl}/${resource}/${id}`;
                let data = params.data;

                if ( data.order_start_date ){
                    data.order_start_date = new Date(data.order_start_date+' 00:00:00').toISOString();
                }

                if ( data.order_end_date ){
                    data.order_end_date = new Date(data.order_end_date+' 23:59:59').toISOString();
                }

                const options = {
                    headers: {
                        authorization: 'Bearer '+localStorage.getItem('token'),
                    }
                }
                return axios.put(url,data,options);
            })
        ).then(responses => ({ data: responses.map(({ data }) => data.data.id) }));
    },
        
    create: async (resource, params) =>{
        const url = `${apiUrl}/${resource}`;
        let data = params.data;

        if ( data.order_start_date ){
            data.order_start_date = new Date(data.order_start_date+' 00:00:00').toISOString();
        }

        if ( data.order_end_date ){
            data.order_end_date = new Date(data.order_end_date+' 23:59:59').toISOString();
        }

        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
            }
        }
        
        return await axios.post(url,data,options).then(({data})=>{
            if ( data.data ){
                return Promise.resolve(data);
            }else{
                return Promise.reject(data);
            };
        });
    },

    delete: async (resource, params) => {
        const url = `${apiUrl}/${resource}/${params.id}`;
        const options = {
            headers: {
                authorization: 'Bearer '+localStorage.getItem('token'),
            }
        }
        return axios.delete(url,options).then(({ data }) => ({ data: data.data }));
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: async (resource, params) => {
        return Promise.all(
            params.ids.map(id => {
                const url = `${apiUrl}/${resource}/${id}`;
                const options = {
                    headers: {
                        authorization: 'Bearer '+localStorage.getItem('token'),
                    }
                }
                return axios.delete(url,options);
            })
        ).then(responses => ({
            data: responses.map(({ data }) => data.data.id),
        }));
    }
        
});

export default DataProvider;