import { List, Datagrid, TextField, DateField, DateTimeInput, NumberInput, SearchInput, AutocompleteInput, useRecordContext, SelectInput, ReferenceInput, ExportButton, DateInput, ShowButton, EditButton, Button, useNotify, NumberField } from 'react-admin';

const filters = [
    <SearchInput placeholder="Search Order Number, Tracking Code or Product here" source="q" alwaysOn />,
    <ReferenceInput label="Account" source="account_id" reference="accounts" allowEmpty>
        <AutocompleteInput/>
    </ReferenceInput>,
    <ReferenceInput label="Product" source="name" reference="products" allowEmpty>
        <AutocompleteInput />
    </ReferenceInput>,
    <AutocompleteInput label="Status" source="status" choices={[{id:'unpaid',name:'unpaid'},{id:'pending',name:'pending'},{id:'canceled',name:'canceled'},{id:'ready_to_ship',name:'ready_to_ship'},{id:'delivered',name:'delivered'},{id:'returned',name:'returned'},{id:'shipped',name:'shipped'},{id:'failed',name:'failed'}]} />,
    <AutocompleteInput label="Shipping Type" source="shipping_type" choices={[{id:'Own Warehouse',name:'Own Warehouse'},{id:'Dropshipping',name:'Dropshipping'}]} />,
    // <DateInput source="order_date" />,
    // <AutocompleteInput label="Order Year" source="order_year" choices={[{id:'2020',name:'2020'},{id:'2021',name:'2021'},{id:'2022',name:'2022'},{id:'2023',name:'2023'},{id:'2024',name:'2024'},{id:'2025',name:'2025'},{id:'2026',name:'2026'},{id:'2027',name:'2027'},{id:'2028',name:'2028'},{id:'2029',name:'2029'},{id:'2030',name:'2030'}]} />,
    // <AutocompleteInput label="Order Month" source="order_month" choices={[{id:'1',name:'Jan'},{id:'2',name:'Feb'},{id:'3',name:'Mar'},{id:'4',name:'Apr'},{id:'5',name:'May'},{id:'6',name:'Jun'},{id:'7',name:'Jul'},{id:'8',name:'Aug'},{id:'9',name:'Sep'},{id:'10',name:'Oct'},{id:'11',name:'Nov'},{id:'12',name:'Dec'}]} />,
    <DateTimeInput label="Start Date" source="order_date_start" />,
    <DateTimeInput label="End Date" source="order_date_end" />,
    <AutocompleteInput label="Dispatched" source="dispatched" choices={[{id:'no',name:'No'},{id:'yes',name:'Yes'}]} />,
    <AutocompleteInput label="Item Received" source="item_received" choices={[{id:'no',name:'No'},{id:'yes',name:'Yes'}]} />,
    <ExportButton />
];

const Dispatched = () => {
    const record = useRecordContext();

    if (!record) return null;
    return record.dispatched ? 'Yes' : 'No';
}

const ItemReceived = () => {
    const record = useRecordContext();

    if (!record) return null;
    return record.item_received ? 'Yes - '+(record.item_payment_recieved || 0) : 'No';
}

const OrderNumber = () => {
    const record = useRecordContext();
    const notify = useNotify();

    if (!record) return null;
    return <Button key={record.id} title="Click to Copy!" onClick={(e)=>{navigator.clipboard.writeText(record.order_number);notify(record.order_number+' Copied to Clipboard')}}><span style={{fontSize:'13px',}}>{record.order_number}</span></Button>;
}

const OrderProduct = () => {
    const record = useRecordContext();
    const notify = useNotify();

    if (!record) return null;
    const full = record.name +' | '+ record.variation;
    return <Button key={record.id} title="Click to Copy!" onClick={(e)=>{navigator.clipboard.writeText(full);notify(full+' Copied to Clipboard')}}><span style={{fontSize:'13px',}}>{full}</span></Button>;
}

const OrderItemId = () => {
    const record = useRecordContext();
    const notify = useNotify();

    if (!record) return null;
    return <Button key={record.id} title="Click to Copy!" onClick={(e)=>{navigator.clipboard.writeText(record.order_item_id);notify(record.order_item_id+' Copied to Clipboard')}}><span style={{fontSize:'13px',}}>{record.order_item_id}</span></Button>;
}

const postRowStyle = (record, index) => ({
    backgroundColor: record.status == 'delivered' ? '#a5e2a5' : (record.status == 'canceled' || record.status == 'returned' || record.status == 'failed' ? '#e2a5a5' : (record.status == 'ready_to_ship' || record.status == 'shipped' ? '#e2d2a5' : '#cccccc')),
});

const OrderList = () => (
    <List filters={filters} sort={{ field: 'order_date', order: 'DESC' }}>
        <Datagrid rowStyle={postRowStyle}>
            <TextField source="account_name" />
            <OrderNumber label="Order Number" />
            <TextField source="tracking_code" />
            <OrderProduct label="Product" />
            <NumberField source="number_of_items" />
            <OrderItemId label="Order Item Id" />
            <TextField source="item_price" />
            <TextField source="purchase_price" />
            <TextField source="status" />
            <TextField source="shipping_type" />
            <ItemReceived label="Return Item Received" />
            <Dispatched label="Dispatched"/>
            <DateField source="order_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
            <TextField source="comments" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default OrderList;